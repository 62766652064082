.dash-div {
    height: 100vh;
}

.dash-body {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
}

.dash-body table th {
    padding: 10px 100px;
}

.dash-body table {
    margin: 0px 50px;
    border: 2px solid black;
}

.page-body-wrapper li #home-button {
    padding: 7px 45px;
}

.dash-body .table-responsive {
    display: flex;
}

.dash-body .table-submitted thead {
    color: white;
    background-color: green;
}

.dash-body .table-pending thead {
    color: white;
    background-color: red;
}

.dash-body .table-pending tbody tr,
.dash-body .table-submitted tbody tr {
    display: flex;
    padding: 5px 0px 0px 20px;
    font-weight: 700;
    text-transform: capitalize;
}

/*  */
.password_reset-modal .username-reset {
    display: flex;
    background-color: none;
    padding: 5px 0;
}

.password_reset-modal .username-reset p {

    font-size: larger;
    font-weight: 600;
    padding-right: 10px;
    margin-left: 20px;
}

.password_reset-modal .username-reset button {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
}

.password_reset-modal ul {
    width: 70%;
}

.password_reset-modal {
    display: flex;
    justify-content: center;
    align-items: center;

}