.btn-reports {
  width: 200px !important;
  margin: 0 30px !important;
}

.btn-re {
  display: flex !important;
}

.horizontal-menu {
  margin-top: 75px !important;
  display: flex !important;
}

.horizontal-menu li {
  margin: 10px !important;
}

.page-body {
  margin-top: 30px !important;
}

.dropdown-basic .dropdown .dropdown-content a {
  margin: 0 !important;
}

iframe {
  display: none;
}

.user-button {
  margin-left: 10px;
}