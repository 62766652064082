.login-error-con {
  text-align: center;
}
.select-user-con {
  margin-bottom: 2rem;
}

.select-user {
  width: 100%;
  height: 35px;
  background-color: #e8f0fe;
  border: none;
  padding: 0 0.5rem;
}

.container-fluid img {
  /* width: 562px !important;
  height: 626px !important; */
}

.container-fluid {
  padding-left: 0 !important;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/**=====================
    25. Authentication CSS Start
==========================**/
.show-hide {
  position: absolute;
  top: 18px;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: capitalize;
  z-index: 2;
}
.show-hide span {
  cursor: pointer;
  font-size: 13px;
  color: #24695c;
}
.show-hide span .show-hide span:before {
  content: "hide";
}
.show-hide span.show:before {
  content: "show";
}

.needs-validation label {
  opacity: 0.7;
  margin-bottom: 10px;
}

.needs-validation .invalid-tooltip {
  top: -35px;
  right: 10px;
}

.login-card {
  background-color: rgba(36, 105, 92, 0.1);
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 12px;
}

.login-form {
  padding: 30px;
  width: 450px;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.login-form h4 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 22px;
}
.login-form h6 {
  font-size: 14px;
  margin-bottom: 25px;
  color: #999;
}
.login-form .btn {
  text-transform: uppercase;
  font-weight: 700;
  margin-left: auto;
  display: block;
}
.login-form p {
  font-weight: 600;
  text-align: center;
  margin-bottom: unset;
}
.login-form .form-group {
  position: relative;
}
.login-form .form-group label {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.login-form .form-group .input-group-text {
  background-color: rgba(36, 105, 92, 0.1);
  border: none;
  color: #24695c;
}
.login-form .form-group input {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.login-form .form-group .form-control::-webkit-input-placeholder {
  font-weight: 400;
}
.login-form .form-group .form-control::-moz-placeholder {
  font-weight: 400;
}
.login-form .form-group .form-control:-ms-input-placeholder {
  font-weight: 400;
}
.login-form .form-group .form-control::-ms-input-placeholder {
  font-weight: 400;
}
.login-form .form-group .form-control::placeholder {
  font-weight: 400;
}
.login-form .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.login-form .form-group .checkbox {
  display: inline-block;
}
.login-form .form-group .checkbox label {
  padding-top: 0;
  margin-top: 0;
  color: #999;
}
.login-form .form-group .checkbox label span {
  color: rgba(36, 105, 92, 0.5);
}
.login-form .form-group .link {
  float: right;
  font-weight: 500;
}
.login-form .form-group .small-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.login-form .form-group .small-group .input-group:nth-child(n + 2) {
  margin-left: 10px;
}
.login-form ul.login-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.login-form ul.login-social li {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: rgba(36, 105, 92, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
}
.login-form ul.login-social li a {
  line-height: 1;
}
.login-form ul.login-social li a svg {
  width: 16px;
  height: auto;
}
.login-form ul.login-social li:nth-child(n + 2) {
  margin-left: 10px;
}
.login-form .login-social-title {
  position: relative;
  margin-bottom: 20px;
  z-index: 2;
}
.login-form .login-social-title::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #999;
  z-index: -1;
}
.login-form .login-social-title h5 {
  font-weight: 600;
  font-size: 16px;
  color: #999;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
}

@media (max-width: 575px) {
  .login-form .form-group .link {
    float: unset;
  }
  .login-form .btn {
    margin-left: unset;
  }
}

@media (max-width: 480px) {
  .login-form {
    width: 100%;
  }
}

/**==========================
      25. Authentication CSS Ends
  ==========================**/

/**=====================
    26. Form CSS Start
==========================**/
.height-35 {
  height: 35px !important;
}

label {
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 20px;
}

.form-control,
.form-select {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
}
.form-control:focus,
.form-select:focus {
  border-color: #e6edef;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form .form-control :focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form.sm-form .form-group {
  margin-bottom: 14px;
}

.theme-form .form-group {
  margin-bottom: 20px;
}
.theme-form .form-group textarea {
  border-color: #efefef;
}
.theme-form .form-group textarea::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}
.theme-form .form-group input[type="text"],
.theme-form .form-group input[type="email"],
.theme-form .form-group input[type="search"],
.theme-form .form-group input[type="password"],
.theme-form .form-group input[type="number"],
.theme-form .form-group input[type="tel"],
.theme-form .form-group input[type="date"],
.theme-form .form-group input[type="datetime-local"],
.theme-form .form-group input[type="time"],
.theme-form .form-group input[type="datetime-local"],
.theme-form .form-group input[type="month"],
.theme-form .form-group input[type="week"],
.theme-form .form-group input[type="url"],
.theme-form .form-group input[type="file"],
.theme-form .form-group select {
  border-color: #e6edef;
  font-size: 14px;
  color: #898989;
}
.theme-form .form-group input[type="text"]::-webkit-input-placeholder,
.theme-form .form-group input[type="email"]::-webkit-input-placeholder,
.theme-form .form-group input[type="search"]::-webkit-input-placeholder,
.theme-form .form-group input[type="password"]::-webkit-input-placeholder,
.theme-form .form-group input[type="number"]::-webkit-input-placeholder,
.theme-form .form-group input[type="tel"]::-webkit-input-placeholder,
.theme-form .form-group input[type="date"]::-webkit-input-placeholder,
.theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
.theme-form .form-group input[type="time"]::-webkit-input-placeholder,
.theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
.theme-form .form-group input[type="month"]::-webkit-input-placeholder,
.theme-form .form-group input[type="week"]::-webkit-input-placeholder,
.theme-form .form-group input[type="url"]::-webkit-input-placeholder,
.theme-form .form-group input[type="file"]::-webkit-input-placeholder,
.theme-form .form-group select::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}
.theme-form .form-group select.form-control:not([size]):not([multiple]) {
  border-color: #efefef;
  font-size: 14px;
}

.theme-form .checkbox label {
  padding-left: 10px;
}

.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}
.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "Or Signup With";
  color: #2b2b2b;
  background: #fff;
}

.theme-form .login-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}
.theme-form .login-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #2b2b2b;
  background: #fff;
  content: "Or Login With";
}

.theme-form .btn-fb {
  background-color: #50598e;
  color: #fff;
  width: 100%;
}

.theme-form .btn-twitter {
  background-color: #6fa2d8;
  color: #fff;
  width: 100%;
}

.theme-form .btn-google {
  background-color: #c64e40;
  color: #fff;
  width: 100%;
}

.form-inline {
  display: -webkit-inline-box;
}
.form-inline .form-group {
  margin-right: 15px;
}
.form-inline .form-group .col-form-label {
  margin-right: 5px;
}

.search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 82px;
  top: 50px;
}

.search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  top: 50px;
  left: 53px;
  color: #8e8e8e;
}

.search-form input {
  border: 1px solid #e6edef;
  padding: 10px 10px 10px 70px;
  border-radius: 50px;
}

.form-label-align-right label {
  text-align: right;
  padding-top: 17px;
  font-weight: 500;
}

.custom-file-label {
  line-height: 1.8;
}
.custom-file-label::after {
  line-height: 1.8;
}

.input-group-text {
  border-color: #e6edef;
  font-weight: 500;
}

.dropdown-menu {
  border: 1px solid #e6edef;
}

.animated-modal .form-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

/**=====================
      26. Form CSS Ends
  ==========================**/
