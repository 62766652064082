td, th{
    border-right: solid 1px #e6edef !important; 
    border-left: solid 1px #e6edef !important;
  }
 .indicator{
  font-size: 20px;
  background-color: #1746A2;
  color: #fff;
 }
 .dcpupolice{
  text-align: center;
  font-size: 30px;
 }