/**=====================
    78. Sidebar CSS Start
==========================**/
header {
  margin-top: 30px !important;
}

.nav-menu .btn {
  width: 150px !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li a:after, .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title h5:after {
    position: absolute;
    content: "";
    top: 30%;
    font-family: FontAwesome;
    font-size: 11px;
    color: #cccccc; }
  
  .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .nav-submenu li a:after {
    position: absolute;
    content: "";
    top: 49%;
    width: 5px;
    height: 1px;
    border-radius: 100%;
    background-color: #59667a; }
  
  .page-wrapper {
    position: relative; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav {
      z-index: 3;
      height: 54px;
      line-height: inherit;
      position: fixed;
      width: 100%;
      top: 77px;
      background: #fff;
      border-top: 1px solid #e6edef;
      border-bottom: 1px solid #e6edef; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .sidebar-user {
        display: none; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .logo-wrapper, .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .logo-icon-wrapper {
        display: none; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar {
        text-align: left;
        position: relative; }
        .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar #mainnav {
          width: 1920px;
          position: fixed;
          left: 0; }
        .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow, .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
          position: absolute;
          top: 0px;
          padding: 14px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          z-index: 1; }
          .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow.disabled, .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow.disabled {
            display: none; }
        .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow {
          left: 0; }
        .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
          right: 0; }
        .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          line-height: normal;
          direction: ltr;
          text-align: left;
          display: -webkit-box !important;
          padding: 0 5px;
          -webkit-transition: all .5s ease;
          transition: all .5s ease;
          white-space: nowrap; }
          .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu li.dropdown a.active .according-menu i:before {
            content: '-'; }
          .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu li.dropdown a.link-nav .according-menu {
            display: none; }
          .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu li.dropdown ul.nav-submenu li a.active {
            color: #24695c; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu li.dropdown ul.nav-submenu li a.active:after {
              background-color: #24695c; }
          .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu .sidebar-main-title {
            display: none; }
          .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li {
            position: relative;
            float: left;
            display: block;
            padding-top: 15px;
            padding-bottom: 15px; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li + li {
              border-left: 1px solid #e6edef; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li:first-child {
              display: none !important; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li > a {
              display: block;
              color: #242934;
              line-height: 23px;
              text-decoration: none;
              text-transform: capitalize;
              padding: 0 18px;
              letter-spacing: 0.04em;
              position: relative;
              border-radius: 4px;
              font-weight: 500; }
              .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li > a svg {
                width: 16px;
                vertical-align: bottom;
                margin-right: 15px; }
              .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li > a .according-menu {
                display: none; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .nav-submenu {
              position: absolute;
              left: 0;
              z-index: 4;
              -webkit-box-shadow: 0 0 1px 0 #898989;
                      box-shadow: 0 0 1px 0 #898989;
              padding: 15px 0px 15px 0px;
              background: white; }
              .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .nav-submenu li {
                border: 0;
                float: none;
                position: relative;
                display: block; }
                .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .nav-submenu li a {
                  border: 0 !important;
                  background: transparent;
                  color: #717171;
                  border-radius: 0 !important;
                  text-transform: capitalize;
                  font-size: 13px;
                  padding: 6px 25px;
                  display: block;
                  letter-spacing: 0.07em;
                  -webkit-transition: all 0.5s ease;
                  transition: all 0.5s ease;
                  position: relative; }
                  .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .nav-submenu li a:after {
                    left: 12px; }
                .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
                  position: absolute;
                  width: 200px;
                  right: -200px;
                  top: 0;
                  background-color: #fff;
                  border: none;
                  padding: 15px 0px 15px 0px;
                  -webkit-box-shadow: 0 0 1px 0 #898989;
                          box-shadow: 0 0 1px 0 #898989; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container {
              position: fixed;
              right: 30px;
              z-index: 4;
              -webkit-box-shadow: 0 0 1px 0 #898989;
                      box-shadow: 0 0 1px 0 #898989;
              padding: 30px;
              background-color: #fff;
              width: 95%;
              border-radius: 4px; }
              .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box {
                width: 20%; }
                .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
                  text-transform: uppercase;
                  font-size: 14px;
                  margin-left: -13px;
                  font-weight: 600; }
                .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content {
                  -webkit-box-shadow: none;
                          box-shadow: none;
                  background-color: transparent;
                  position: relative;
                  display: block;
                  padding: 0; }
                  .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li {
                    border: 0;
                    float: none;
                    position: relative;
                    display: block; }
                    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
                      border: 0 !important;
                      background: transparent;
                      color: #717171;
                      border-radius: 0 !important;
                      text-transform: capitalize;
                      padding: 3px 0;
                      font-size: 13px;
                      display: block;
                      letter-spacing: 0.07em;
                      line-height: 1.9;
                      position: relative; }
                      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:after {
                        position: absolute;
                        content: "";
                        left: -12px;
                        top: 50%;
                        width: 5px;
                        height: 1px;
                        background-color: #59667a; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li:hover > a:after {
              left: 0;
              right: 0;
              opacity: 1; }
            .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li.mega-menu {
              position: unset; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav.white-active .main-navbar .nav-menu > li .nav-link.active {
        background-color: white;
        color: #ba895d; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav.overlay-white {
        background-color: rgba(255, 255, 255, 0.08); }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .toggle-nav {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      cursor: pointer; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .toggle-nav i {
        font-size: 24px; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .mega-box .link-section .demo ul li a {
      position: relative; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .mega-box .link-section .demo ul li a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .mega-box .link-section .demo ul li a:focus {
        padding-top: 3px;
        padding-bottom: 3px; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .mega-box .link-section .demo .new {
      position: absolute;
      top: -8px;
      right: 0px;
      color: white;
      background-color: #24695c;
      padding: 2px 7px;
      text-transform: uppercase;
      font-size: 9px;
      line-height: 1.3; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu .mega-box .link-section .demo .title {
      border-bottom: 2px solid red !important; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
      min-height: calc(100vh - 130px);
      margin-top: 130px;
      padding-top: 30px; }
    .page-wrapper.horizontal-wrapper .page-main-header {
      -webkit-box-shadow: none;
              box-shadow: none; }
      .page-wrapper.horizontal-wrapper .page-main-header .main-header-right .form-control-plaintext {
        width: auto !important; }
      .page-wrapper.horizontal-wrapper .page-main-header .main-header-right .toggle-sidebar {
        display: none; }
    .page-wrapper.compact-wrapper .page-main-header.close_icon {
      margin-left: 0;
      width: calc(100% - 0px); }
    .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
      position: fixed;
      border-bottom: none;
      top: 83px;
      z-index: 8;
      height: 100%;
      line-height: inherit;
      background: #fff;
      border-right: 1px solid #e6edef;
      width: 290px;
      text-align: left;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease; }
      .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-main-title > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e6edef; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-main-title > div h6 {
          color: #24695c;
          font-size: 18px;
          margin-bottom: 0; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-main-title > div p {
          color: #242934;
          margin-bottom: 0; }
      .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user {
        padding: 30px;
        position: relative;
        border-bottom: 1px solid #e6edef;
        margin-bottom: 20px; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user img {
          border: 10px solid rgba(36, 105, 92, 0.2); }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user .setting-primary {
          position: absolute;
          top: 30px;
          right: 35px; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user h6 {
          margin-bottom: 0; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user p {
          color: #999;
          font-family: "Roboto", sans-serif;
          font-size: 13px; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user .badge-bottom {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 95px; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user .badge-bottom .badge {
            padding: 4px 6px 3px;
            -webkit-box-shadow: 0px 7px 10px rgba(36, 105, 92, 0.3);
                    box-shadow: 0px 7px 10px rgba(36, 105, 92, 0.3);
            font-family: "Roboto", sans-serif; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: distribute;
              justify-content: space-around;
          margin-top: 20px; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user ul li {
            position: relative; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user ul li + li::before {
              position: absolute;
              content: '';
              left: -14px;
              top: 15px;
              width: 2px;
              height: 15px;
              background-color: #e6edef; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user ul li span {
              font-weight: 600; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user ul li p {
              font-size: 12px;
              margin-bottom: -5px; }
      .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav.close_icon {
        margin-left: 0;
        opacity: 0;
        visibility: hidden; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav.close_icon ~ .page-body {
          margin-left: 0px;
          -webkit-transition: 0.5s;
          transition: 0.5s; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav.close_icon ~ footer {
          margin-left: 78px;
          -webkit-transition: 0.5s;
          transition: 0.5s; }
      .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .left-arrow, .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .right-arrow {
        display: none; }
      .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu {
        border-radius: 0;
        height: calc(100vh - 355px);
        left: -300px;
        z-index: 99;
        -webkit-transition: color 1s ease;
        transition: color 1s ease;
        overflow: hidden;
        overflow-y: auto;
        color: rgba(0, 0, 0, 0);
        padding-bottom: 15px; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu.custom-scrollbar::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px transparent; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb, .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu.custom-scrollbar::-webkit-scrollbar {
          width: 6px;
          border-radius: 13px; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 0 10px;
                  box-shadow: inset 0 0 0 10px;
          background-color: #f5f7fb; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu:hover.custom-scrollbar {
          color: rgba(36, 105, 92, 0.2); }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .dropdown ul.nav-submenu li a.active {
          color: #24695c; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .dropdown ul.nav-submenu li a.active:after {
            color: #24695c; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .dropdown ul.nav-submenu li:hover a {
          color: #24695c; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu li a {
          font-weight: 600; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu li a.link-nav .according-menu {
            display: none; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu li:last-child {
          border-bottom: none; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu.opennav {
          left: 0; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .back-btn {
          display: block;
          width: 100%;
          padding: 0; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .back-btn .mobile-back {
            padding: 20px;
            color: #2b2b2b;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid #efefef;
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center; }
        .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li {
          display: block;
          float: none;
          width: 100%;
          padding: 0 20px; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li span {
            font-weight: 600; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li a {
            padding: 10px 15px;
            position: relative;
            color: #222222; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li a svg {
              width: 16px;
              margin-right: 15px;
              vertical-align: bottom;
              float: none;
              stroke-width: 2.5px; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li a .sub-arrow {
              right: 20px;
              position: absolute;
              top: 10px; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li a .sub-arrow i {
                display: none; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li a .sub-arrow:before {
                display: none; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li:first-child {
            display: none !important; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu {
            width: 100%;
            padding: 0;
            position: relative !important;
            display: none; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu > li a {
              padding-left: 45px !important;
              font-weight: 400; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu .submenu-title .according-menu {
              top: 8px; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu.opensubmenu {
              display: block; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
              display: none;
              position: relative !important;
              right: 0;
              width: 100%;
              padding: 0; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.opensubchild {
                display: block; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
                padding: 6px 45px;
                padding-left: 56px !important; }
                .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a:after {
                  display: none; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li a {
              padding: 8px 25px;
              font-size: 13px;
              color: #717171;
              display: block;
              position: relative;
              letter-spacing: 0.07em; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li a:after {
                left: 26px; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-submenu li a:hover {
                margin-left: 0; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container {
            display: none;
            padding: 0;
            position: relative; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container.opensubmenu {
              display: block; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box {
              width: 100%;
              padding: 0; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title {
                position: relative; }
                .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
                  margin-bottom: 0;
                  line-height: 1.9;
                  padding: 6px 25px 6px 45px;
                  font-size: 13px;
                  font-weight: 400;
                  color: #242934;
                  position: relative;
                  cursor: pointer; }
                  .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title h5:after {
                    left: 26px; }
                .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title .according-menu {
                  top: 8px; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content {
                display: none; }
                .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu {
                  display: block; }
                  .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu ul li a {
                    padding: 6px 25px 6px 50px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #242934;
                    display: block; }
                    .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu ul li a:after {
                      display: none; }
                .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
                  line-height: 1.9; }
                  .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover {
                    margin-left: 0; }
          .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link {
            border-radius: 10px;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease; }
            .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link.active, .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link:hover {
              background-color: #24695c;
              color: #d0efe9;
              -webkit-transition: all 0.5s ease;
              transition: all 0.5s ease;
              position: relative; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link.active .according-menu i, .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link:hover .according-menu i {
                color: #d0efe9; }
              .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link.active svg, .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link:hover svg {
                color: #d0efe9 !important; }
    .page-wrapper.compact-wrapper .page-body-wrapper header .menu nav {
      margin-left: auto;
      margin-right: 30px; }
    .page-wrapper.compact-wrapper .page-body-wrapper .mega-box .link-section .demo ul li a span img {
      top: 30px;
      right: -60px; }
    .page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
      -ms-flex-preferred-size: unset;
          flex-basis: unset; }
    .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
      position: absolute;
      right: 15px;
      top: 12px;
      display: block; }
      .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
        vertical-align: middle;
        color: #000; }
    .page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
      border: none; }
    .page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav {
      position: absolute;
      z-index: 2;
      right: 145px;
      top: 6px; }
      .page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
        font-size: 18px;
        padding-left: 5px;
        text-transform: uppercase; }
    .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
      padding-top: 30px;
      margin-left: 290px; }
    .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper .according-menu {
      display: none !important; }
    .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav {
      width: 150px; }
      .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user {
        display: none; }
      .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu {
        height: calc(100vh - 80px);
        padding-top: 40px;
        padding-bottom: 40px; }
        .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .sidebar-main-title {
          display: none; }
        .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link {
          text-align: center;
          padding-left: unset;
          padding-right: unset; }
          .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link.active {
            background-color: transparent; }
            .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link.active svg {
              color: #24695c !important; }
            .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link.active span {
              color: #24695c !important; }
          .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link svg {
            display: block;
            width: auto;
            height: 50px;
            margin-left: auto;
            margin-right: auto;
            padding: 15px;
            border-radius: 5px; }
          .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu > li .nav-link span {
            display: block;
            word-wrap: break-word;
            margin-top: -5px; }
        .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .dropdown .menu-title.active ~ .menu-content {
          background-color: #fff;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          display: none;
          padding-top: 20px;
          -webkit-box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
                  box-shadow: 0 0 20px rgba(25, 124, 207, 0.08); }
        .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu .dropdown .menu-content {
          position: fixed !important;
          top: 75px;
          height: calc(100vh - 0px);
          overflow: auto;
          left: 150px;
          width: 230px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
      .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper header.main-nav .sidebar-main-title {
        display: none !important; }
    .page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper .page-body {
      margin-left: 150px; }
    .page-wrapper.modern-sidebar {
      padding: 30px;
      background-color: rgba(36, 105, 92, 0.15);
      height: 100vh;
      overflow: hidden; }
      .page-wrapper.modern-sidebar .page-main-header {
        top: 30px;
        width: calc(100% - 60px);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 2; }
        .page-wrapper.modern-sidebar .page-main-header.close_icon {
          width: calc(100% - 60px); }
      .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
        margin-bottom: auto;
        margin-top: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-radius: 20px;
        padding-top: 75px; }
        .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav {
          top: 113px;
          height: calc(100vh - 145px);
          overflow: hidden;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav.close_icon {
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate(0);
                    transform: translate(0); }
            .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav.close_icon ~ footer {
              margin-left: 30px; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu {
            height: calc(100vh - 430px); }
        .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
          margin-left: 290px;
          overflow-y: scroll !important;
          min-height: calc(100vh - 185px);
          max-height: calc(100vh - 185px);
          margin-bottom: auto;
          margin-top: auto;
          border-top-right-radius: 20px; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
            width: 6px; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
            background-color: rgba(36, 105, 92, 0.2);
            border-radius: 13px;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px; }
      .page-wrapper.modern-sidebar.compact-wrapper footer {
        position: fixed;
        left: 0;
        width: calc(100% - 60px);
        z-index: 10;
        margin-left: 30px;
        margin-bottom: 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px; }
  
  .main-navbar ul.nav-pills {
    display: -webkit-box  !important;
    display: -ms-flexbox  !important;
    display: flex  !important;
    border-top: 1px solid #e6edef;
    border-bottom: 1px solid #e6edef; }
    .main-navbar ul.nav-pills .nav-item {
      width: 50%; }
      .main-navbar ul.nav-pills .nav-item:last-child {
        border-left: 1px solid #e6edef; }
      .main-navbar ul.nav-pills .nav-item .nav-link {
        font-size: 14px;
        color: #2b2b2b;
        font-weight: 600;
        text-align: center;
        padding: 15px 0;
        cursor: pointer; }
        .main-navbar ul.nav-pills .nav-item .nav-link.active {
          border-radius: unset;
          background-color: rgba(36, 105, 92, 0.1);
          color: #24695c; }
  
  .main-navbar .tab-content {
    padding-left: 20px;
    padding-right: 20px; }
  
  .rtl .link-section {
    float: right; }
  
  .rtl .mega-box .link-section .demo ul li a span img {
    right: unset;
    left: -120px; }
  
  @media (min-width: 992px) {
    .responsive-btn {
      display: none; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li .nav-submenu {
      width: 200px;
      opacity: 0;
      visibility: hidden;
      border-radius: 5px;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li .nav-submenu a .sub-arrow {
        position: absolute;
        right: 20px;
        top: 5px;
        color: #59667a;
        font-size: 10px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
        display: none;
        border-radius: 4px; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu {
        display: block; }
      .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li .nav-submenu li a:hover .sub-arrow {
        right: 16px;
        color: #222222;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li .mega-menu-container {
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li:hover .nav-submenu {
      opacity: 1;
      visibility: visible;
      margin-top: 20px;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header .main-navbar .nav-menu > li:hover .mega-menu-container {
      opacity: 1;
      visibility: visible;
      margin-top: 20px;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
      display: none; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
      display: none !important; }
    .rtl .mega .full-mega-menu {
      float: left !important;
      left: 50% !important;
      right: auto !important; }
      .rtl .mega .full-mega-menu.center-mega-menu {
        margin-right: 0 !important;
        margin-left: 0 !important;
        left: unset !important;
        right: 50% !important; } }
  
  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    .page-wrapper.modern-sidebar .page-main-header .main-header-right .nav-right.right-menu ul {
      padding-left: 20px;
      padding-right: 20px; } }
  
  @media screen and (min-width: 1200px) and (max-width: 1660px) {
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu > li a svg {
      margin-right: 6px; }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu .nav-submenu.submenu-right-xl li .nav-sub-childmenu {
      left: -200px;
      right: unset; } }
  
  @media (max-width: 1460px) {
    .rtl .pixelstrap .mega .full-mega-menu {
      margin-left: 0 !important;
      margin-right: 162px !important; }
    header .main-navbar .nav-menu > li {
      margin-right: 8px; } }
  
  @media (max-width: 1199px) {
    .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav.close_icon ~ footer {
      margin-left: unset; }
    .page-wrapper.modern-sidebar {
      padding: unset;
      height: auto; }
      .page-wrapper.modern-sidebar .page-main-header {
        top: 0;
        border-radius: unset;
        width: 100%; }
        .page-wrapper.modern-sidebar .page-main-header.close_icon {
          width: 100%; }
      .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
        border-radius: unset;
        margin-top: unset;
        margin-bottom: unset;
        padding-top: unset; }
        .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav {
          top: 85px;
          height: auto; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu {
            height: calc(100vh - 355px); }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper header.main-nav.close_icon ~ footer {
            width: 100%;
            margin-left: unset; }
        .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
          max-height: unset;
          max-height: unset;
          min-height: calc(100vh - 76px);
          margin-top: 76px; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
            display: none; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
            display: none; }
          .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
            display: none; }
      .page-wrapper.modern-sidebar.compact-wrapper footer {
        position: unset;
        width: auto;
        border-radius: unset;
        margin-left: 275px;
        margin-bottom: unset; } }
  
  @media (max-width: 991px) {
    .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
      margin-top: 65px; } }
  
  @media (max-width: 767px) {
    header .menu .brand-logo img {
      width: 120px; } }
  
  @media (max-width: 576px) {
    header .menu nav {
      margin-right: 20px; }
    .toggle-nav i {
      font-size: 20px; } }
  
  /**=====================
      78. Sidebar CSS Ends
  ==========================**/