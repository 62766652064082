.modal-header {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
}

.modal-title {
  align-items: center;
}

.modal-header h5 {
  font-size: 30px;
  font-weight: bolder;
  padding-top: 20px;
}

.modal-body th,
.modal-body td {
  border: solid 1px #111111 !important;
  padding: 0.2rem 0.5rem;
  border-collapse: collapse;
  /* border-right: solid 1px #111111 !important; 
    border-left: solid 1px #030303 !important; */
}

.modal-body td {
  font-weight: 600;
}

.modal-body table input {
  border: none;
}

.modal-body label {
  width: 40% !important;
  margin-left: 12em !important;
  font-size: 18px;
}

.modal-body h5,
.modal-body h4 {
  /* text-align: left; */
  /* margin-left: 4em; */
  margin-top: 2em;
}

.modal-body h6 {
  margin-top: 1.5em;
}

.modal-body h3 {
  text-align: center;
}

.modal-body textarea {
  /* margin-left: 12em !important; */
  width: 80% !important;
}

table tr .extra {
  height: 50px;
  background: rgb(111, 203, 203);
}

.qprhighcourt input {
  width: 50px;
}

.modal-body p {
  margin-bottom: auto;
  text-transform: capitalize;
}

#violence-form table input {
  width: 75px;
}

.modal-body form {
  margin-left: 30px;
}